import React, { useState } from 'react'
import cx from 'classnames'
import { gsap } from 'gsap'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ faq }) => {
  if (!faq || faq.isHide) return null

  const [activeIndex, setActiveIndex] = useState(false)

  const clickHandler = index => {
    const faqElementOld = document.querySelector(`.${styles.itemText}[data-index="${activeIndex}"]`)
    const faqElementNew = document.querySelector(`.${styles.itemText}[data-index="${index}"]`)

    if (activeIndex === index) {
      gsap.to(faqElementOld, { height: '20', duration: 0.3, onComplete: () => faqElementOld.classList.remove(styles.itemTextActive) })
      setActiveIndex(false)
    } else {
      if (faqElementOld) {
        gsap.to(faqElementOld, { height: '20', duration: 0.3, onComplete: () => faqElementOld.classList.remove(styles.itemTextActive) })
      }
      faqElementNew.classList.add(styles.itemTextActive)
      gsap.to(faqElementNew, { height: 'auto', duration: 0.3 })
      setActiveIndex(index)
    }
  }

  return (
    <div className={styles.faq}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: faq.faqList.title }} />
        </div>
        <div className={styles.right}>
          {faq.faqList.items &&
            faq.faqList.items.map((item, index) => (
              <div
                className={cx(styles.item, {
                  [styles.itemShow]: activeIndex === index,
                })}
                key={item._key}
                onClick={() => clickHandler(index)}
              >
                <h3 className={styles.itemTitle}>{item.title}</h3>
                <p className={styles.itemText} data-index={index}>{prepareParagraph(item.text)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
