import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import Header from '../components/BuyEnclomiphenePage/Header'
import Faq from '../components/BuyEnclomiphenePage/Faq'
import Reviews from '../components/BuyEnclomiphenePage/Reviews'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      <Faq faq={data.faq} />
      <Reviews reviews={data.reviews} />
    </Layout>
  )
}