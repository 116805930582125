import React, { useState, useEffect } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  const [selectValue, setSelectValue] = useState(header.subscriptions[0])
  const [selectOpen, setSelectOpen] = useState(false)

  const selectOptionHandler = (e, item) => {
    e.stopPropagation()
    setSelectValue(item)
    setSelectOpen(false)
  }

  return (
    <div className={styles.header}>
      <div className={styles.image}>
        <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
          <p className={styles.description}>{header.description}</p>
          <div className={styles.plans}>
            <div className={styles.plansInfo}>
              <div className={styles.top}>
                <p className={styles.protocol}>{header.protocolName}</p>
                {header.rating?.url && (
                  <Link to={header.rating.url} className={styles.rating}>
                    {header.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(header.rating.starsImage)} alt={header.rating?.text}/>}
                    {header.rating.text && <span className={styles.ratingText}>{header.rating.text}</span>}
                  </Link>
                )}
              </div>
              {selectValue && (
                <div className={styles.bottom}>
                  {selectValue.save ? (
                    <p className={styles.save}>{selectValue.save}</p>
                  ) : (
                    <p className={styles.saveEmpty} />
                  )}
                  <p className={styles.price} dangerouslySetInnerHTML={{ __html: selectValue.price }} />
                  <p className={styles.every}>{selectValue.text}</p>
                </div>                
              )}
            </div>
            <div className={styles.plansControls}>
              {selectValue && (
                <div className={styles.top}>
                  <p className={styles.label}>{header.subscriptionSelectLabel}</p>
                  <div className={cx(styles.select, {
                    [styles.selectOpen]: selectOpen
                  })} onClick={() => setSelectOpen(true)}>
                    <p className={styles.selectValue}>{selectValue.period}</p>
                    <ul className={styles.selectList}>
                      {header.subscriptions && header.subscriptions.map(plan => (
                        <li key={plan._key} className={styles.selectOption} onClick={(e) => selectOptionHandler(e, plan)}>{plan.period}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className={styles.bottom}>
                <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{header.buttonTitle}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
